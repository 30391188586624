import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Electrolize|Orbitron:400,900');
  font-family: 'Electrolize', sans-serif;
  body {
    background-color: #131313;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.05em;
  }
  ::-moz-selection {
    background-color: #fff300;
    color: black;
  }
  ::selection {
    background-color: #fff300;
    color: black;
  }
  strong {
    background-color: #fff300;
    color: #131313;
    padding: 0 4px 0 14px;
    position: relative;
    margin-right: 10px;
    ::before {
      content: '';
      border-top: 10px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 10px solid #131313;
      position: absolute;
      left: 0;
      top: 0;
    }
    ::after {
      content: '';
      border-top: 10px solid transparent;
      border-bottom: 11px solid transparent;
      border-left: 10px solid #fff300;
      position: absolute;
      right: -10px;
      top: 0;
    }
  }
  @keyframes flicker {
    from {opacity: 0.3}
    to {opacity: 1}
  }
  svg {  
    ${() => {
      let animateRects = ``;
      for (let i = 1; i < 37; i++) {
        animateRects +=
          `
        rect:nth-child(` +
          i +
          `) {
          animation: flicker ${Math.random() * 2000 + 1000}ms linear infinite;
        }
      `;
      }
      return animateRects;
    }}
    // Clean this up. This gives each group for the down arrow svg independant animations.
    g:nth-child(1) {
      ${() => {
        let animateRects = ``;
        for (let i = 1; i < 37; i++) {
          animateRects +=
            `
          rect:nth-child(` +
            i +
            `) {
            animation: flicker ${Math.random() * 2000 + 1000}ms linear infinite;
          }
        `;
        }
        return animateRects;
      }}
    }
    g:nth-child(2) {
      ${() => {
        let animateRects = ``;
        for (let i = 1; i < 37; i++) {
          animateRects +=
            `
          rect:nth-child(` +
            i +
            `) {
            animation: flicker ${Math.random() * 2000 + 1000}ms linear infinite;
          }
        `;
        }
        return animateRects;
      }}
    }
    g:nth-child(3) {
      ${() => {
        let animateRects = ``;
        for (let i = 1; i < 37; i++) {
          animateRects +=
            `
          rect:nth-child(` +
            i +
            `) {
            animation: flicker ${Math.random() * 2000 + 1000}ms linear infinite;
          }
        `;
        }
        return animateRects;
      }}
    }
  }
`;

export default GlobalStyle;
