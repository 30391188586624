import React from 'react';
import Transition from './components/transition';
import styled from 'styled-components';

import GlobalStyle from './styles';

const TemplateWrapper = ({ children, location }) => (
  <TransitionContainer>
    <GlobalStyle />
    <Transition location={location}>{children}</Transition>
  </TransitionContainer>
);

const TransitionContainer = styled.div`
  height: 100%;
  .noUnderline {
    text-decoration: none;
  }
  > div {
    height: 100%;
    > div {
      height: 100%;
    }
  }
`;

export default TemplateWrapper;
